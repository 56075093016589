
import Vue from 'vue';
import { LOCALES } from '@/constants';
import { eTranslationLocaleCode } from '@/enums';
import { saveSettings } from '@/util-functions/user-utils';

export default Vue.extend({
  data(): {
    locales: Record<string, string>;
  } {
    return {
      locales: LOCALES,
    };
  },
  computed: {
    currLocale(): string {
      return this.$store.state.locale;
    },
    localesArray(): TextValue[] {
      return Object.entries(this.locales).map(([value, text]) => {
        return {
          text,
          value,
        };
      });
    },
  },
  methods: {
    setTranslationsFor(localeCode: eTranslationLocaleCode): void {
      this.$store.commit('locale', localeCode);
      if (this.$store.state.currUser) {
        saveSettings({
          locale: localeCode,
        });
      }
    },
  },
});
