
import Vue from 'vue';
import validationRules from '@/validation-rules';
import SitchLanguageSelect from '@/components/custom-ui-components/SitchLanguageSelect.vue';
import { format } from 'date-fns';
import { ePaymentStatus } from '@/enums';
import { endpoints } from '@/constants';
import initializeSitchButtons from 'sitch-embed';
import { fetchSignInMethodsForEmail, User } from 'firebase/auth';
import { sitchClientUrl, fbAuth, standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { formatCurrency } from '@/util-functions/misc-utils';
import { showSnackBar } from '@/util-functions/notice-utils';
import { getSubscriptionData, onActivatePremiumClick } from '@/util-functions/sitch-premium-utils';
import { copyText, deactivatePremium, deletePlatformUser, updateUserDoc } from '@/util-functions/user-utils';
import { StripeSubscription } from '@/store';
import { signOutAccount } from '@/util-functions/auth-utils';

export default Vue.extend({
  components: {
    SitchLanguageSelect,
  },
  props: {
    paymentStatus: {
      type: String,
      default: '',
    },
  },
  data(): {
    stripeInvoiceTableHeaders: TableHeaders;
    usesNonEmailProvider: boolean;
    ePaymentStatus: typeof ePaymentStatus;
    rules: typeof validationRules;
    isUserFormValid: boolean;
    isPasswordFormValid: boolean;
    password: string;
    passwordConfirm: string;
    emailWasChanged: boolean;
    userForm: {
      email: string;
      displayName: string;
    };
  } {
    return {
      stripeInvoiceTableHeaders: [
        {
          text: t.date,
          value: 'created',
          sortable: true,
        },
        {
          text: t.amount,
          value: 'amount_paid',
          sortable: true,
        },
        { text: t?.invoiceUrl, value: 'url', sortable: false, align: 'center' },
        { text: t?.invoicePdf, value: 'pdf', sortable: false, align: 'center' },
      ],
      usesNonEmailProvider: true,
      ePaymentStatus,
      rules: validationRules,
      isUserFormValid: false,
      isPasswordFormValid: false,
      password: '',
      passwordConfirm: '',
      emailWasChanged: false,
      userForm: {
        email: this.$store.state.currUser.email,
        displayName: this.$store.state.currUser.displayName,
      },
    };
  },
  computed: {
    isPremiumActive(): boolean {
      return this.$store.state.isPremiumActive;
    },
    premiumSubData(): StripeSubscription | null {
      return this.$store.state.premiumSubData;
    },
    recentStripeInvoices(): any[] {
      return this.$store.state.recentStripeInvoices;
    },
    premiumCancelDate(): number {
      return this.$store.state.premiumCancelDate;
    },
    hasPermanentPremium(): boolean {
      return this.$store.state.currUserModeGateway?.hasPermanentPremium;
    },
    comparePasswordRules(): string | boolean {
      return this.password !== this.passwordConfirm ? t?.passwordsDoNotMatch : true;
    },
    formattedSubEndDate(): null | string {
      const periodEnd = this.$store.state.premiumSubData?.current_period_end;
      if (periodEnd) {
        return (this.premiumCancelDate ? t?.sitchPremiumActiveUntil : t?.sitchPremiumActive).supplant([format(periodEnd * 1000, 'MMM dd yyyy')]);
      }
      return null;
    },
    isSitchLinkActivated(): boolean {
      return this.$store.state.currUserModeGateway.isSitchLinkActivated;
    },
    userLink(): string {
      return `${sitchClientUrl}?u=${this.$store.state.userId}`;
    },
  },
  mounted() {
    fetchSignInMethodsForEmail(fbAuth, this.$store.state.currUser.email)
      .then((signInMethods) => {        
        this.usesNonEmailProvider = signInMethods.length > 0 && signInMethods[0] !== 'password';
      })
      .catch((error) => {
        showError(`Could not get sign in methods.`, error, true);
      });
    switch (this.paymentStatus) {
      case ePaymentStatus.sitchPremiumdPaymentSucceeded:
        showSnackBar(t?.sitchPremiumdPaymentSucceeded);
        getSubscriptionData();
        break;
      case ePaymentStatus.sitchPremiumdPaymentCancelled:
        showSnackBar(t?.sitchPremiumdPaymentCancelled);
        break;
    }
    initializeSitchButtons();
  },
  methods: {
    copyText(str: string) {
      return copyText(str);
    },
    deletePlatformUser() {
      deletePlatformUser();
    },
    formatCurrency(price: number, currency: string): string {
      return formatCurrency(price, currency);
    },
    formatDate(date: number) {
      return format(date * 1000, 'MMM dd yyyy, h:mm a');
    },
    getInvoices() {
      const customerId = this.$store.state.premiumSubData?.customer;
      if (customerId) {
        standardApiFetch(endpoints.getInvoices, {
          customerId,
        }).then((response) => {
          this.$store.commit('recentStripeInvoices', response.successfulResponse.invoices.data);
        });
      }
    },
    updateAccountInfo() {
      if ((this.$refs.userForm as any).validate()) {
        const user: User | null = fbAuth.currentUser;

        if (!user) {
          showError(`No user exists`, null, true);
          return;
        }

        if (user) {
          user
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const wasCreatedBy = this.$store.state.currUser.wasCreatedBy || '';
              const update: AuthUserUpdate = {
                displayName: '',
                email: '',                                   
                password: this.password,                
                userId: user.uid,   
                managedUserId: '',             
                wasCreatedBy,   
                idToken,
              };

              if (this.userForm.email !== user.email) {
                update.email = this.userForm.email;
              }
              if (this.userForm.displayName !== user.displayName) {
                update.displayName = this.userForm.displayName;
              }

              standardApiFetch(endpoints.updateAuthUser, update).then(() => {
                showSnackBar(t?.passwordUpdated);
                updateUserDoc(this.userForm);
              });
            })
            .catch((error) => {
              showError(`Could not get user token to make request`, error, true);
            })
            .finally(() => {
              this.password = '';
              this.passwordConfirm = '';
            });
        }
      }
    },
    onActivatePremiumClick() {
      onActivatePremiumClick();
    },
    onReactivatePremiumClick() {
      const premiumSubscriptionId = this.$store.state.currUserModeGateway.premiumSubscriptionId || '';
      if (premiumSubscriptionId) {
        standardApiFetch(endpoints.reactivateStripeSubscription, {
          premiumSubscriptionId,
        }).then((response) => {
          this.$store.commit('premiumSubData', response.successfulResponse.subscription);
        });
      }
    },
    onDeactivatePremiumClick() {
      deactivatePremium();
    },
    updatePassword() {
      if ((this.$refs.passwordform as any).validate()) {
        if (!this.password) {
          showError(`Password cannot be blank.`);
          return;
        }

        const user: User | null = fbAuth.currentUser;
        if (user) {
          user
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const wasCreatedBy = this.$store.state.currUser.wasCreatedBy || '';
              const update: AuthUserUpdate = {
                displayName: '',
                email: '',                                   
                password: this.password,     
                userId: user.uid,   
                managedUserId: '',             
                wasCreatedBy,   
                idToken,
              };
              standardApiFetch(endpoints.updateAuthUser, update).then(() => {
                showSnackBar(t?.passwordUpdated);
                signOutAccount();
              });
            })
            .catch((error) => {
              showError(`Could not get user token to make request`, error, true);
            })
            .finally(() => {
              this.password = '';
              this.passwordConfirm = '';
            });
        }
      }
    },
  },
});
