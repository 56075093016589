
import Vue from 'vue';
import validationRules from '@/validation-rules';
import { getEmptyAestheticTheme, defaultThemeBackgroundColor } from '@/constants';
import { eMetaTheme, eCardStyle } from '@/enums';
import SitchThemeSelector from '@/components/custom-ui-components/SitchThemeSelector.vue';
import SitchThemePreview from '@/components/custom-ui-components/SitchThemePreview.vue';
import SitchFontPreview from '@/components/custom-ui-components/SitchFontPreview.vue';
import SitchImageUpload from '@/components/custom-ui-components/SitchImageUpload.vue';
import { t } from '@/util-functions/language-utils';
import { getParameterByName, loadFonts } from '@/util-functions/misc-utils';
import { showSuccess } from '@/util-functions/notice-utils';
import { isPremiumActivated, updateUserModeGatewayDoc } from '@/util-functions/user-utils';
import { allFontImageNames } from '@/all-font-images';
import { handleImages } from '@/util-functions/image-utils';

const formatVariants = (variants: string[]): { text: string; value: string }[] => {
  return variants.map((value) => {
    return {
      text: t[`fontVariant${value}`],
      value,
    };
  });
};

export default Vue.extend({
  components: {
    SitchThemeSelector,
    SitchThemePreview,
    SitchFontPreview,
    SitchImageUpload,
  },
  data(): {
    eCardStyle: typeof eCardStyle;
    rules: typeof validationRules;
    backgroundSwatchesMulticolor: string[][];
    backgroundSwatchesMonochrome: string[][];
    backgroundSwatchesMonochromeGradients: string[][];
    primaryFontVariants: { text: string; value: string }[];
    headerFontVariants: { text: string; value: string }[];
    showPreview: boolean;
    themeData: AestheticTheme;
    showBackgroundDialog: boolean;
    showAccentDialog: boolean;
    currThemeForEdit: AestheticTheme | null;
    allFontImageNames: string[];
    publicPath: string;
    imageSizesForBackgroundImage: ImageSizes;
    imageSizesForLogoImage: ImageSizes;
    eMetaTheme: typeof eMetaTheme;
  } {
    // WARNING: If the default primary font is changed, then the variant values will have to be changed as well.
    const defaultFontVariants = formatVariants([
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
      'bold',
    ]);
    const themeData = getEmptyAestheticTheme();

    return {
      eCardStyle,
      rules: validationRules,
      showBackgroundDialog: false,
      showAccentDialog: false,
      backgroundSwatchesMulticolor: [
        // Dark Grandients
        ['linear-gradient(to right, #544a7d, #ffd452)', 'linear-gradient(to right, #FDC830, #F37335)', 'linear-gradient(to right, #fc4a1a, #f7b733)', 'linear-gradient(to right, #ff512f, #f09819)'], // yellow
        ['linear-gradient(to right, #eb3349, #a83279)', 'linear-gradient(to right, #ff512f, #dd2476)', 'linear-gradient(to right, #d38312, #a83279)', 'linear-gradient(to right, #3a1c71, #d76d77)'], // orange
        ['linear-gradient(to right, #e53935, #ff9068)', 'linear-gradient(to right, #ff4b1f, #a83279)', 'linear-gradient(to right, #fd746c, #a83279)', 'linear-gradient(to right, #d66d75, #a83279)'], // pink
        ['linear-gradient(to right, #1f7f01, #155700)', 'linear-gradient(to right, #56ab2f, #134e5e)', 'linear-gradient(to right, #283c86, #45a247)', 'linear-gradient(to right, #44a08d, #093637)'], // green
        ['linear-gradient(to right, #5a3f37, #2c7744)', 'linear-gradient(to right, #348f50, #56b4d3)', 'linear-gradient(to right, #134e5e, #71b280)', 'linear-gradient(to right, #d65a64, #87cc85)'], // green 2
        ['linear-gradient(to right, #5ac4f2, #2f80ed)', 'linear-gradient(to right, #3a7bd5, #3a6073)', 'linear-gradient(to right, #457fca, #5691c8)', 'linear-gradient(to right, #0575e6, #021b79)'], // light blue
        ['linear-gradient(to right, #00d2ff, #3a7bd5)', 'linear-gradient(to right, #00c6ff, #0072ff)', 'linear-gradient(to right, #02aab0, #00cdac)', 'linear-gradient(to right, #d66547, #58b8c7)'], // light blue 2
        ['linear-gradient(to right, #141e30, #243b55)', 'linear-gradient(to right, #005c97, #363795)', 'linear-gradient(to right, #000428, #004e92)', 'linear-gradient(to right, #360033, #243b55)'], // blue
        ['linear-gradient(to right, #136a8a, #267871)', 'linear-gradient(to right, #4b6cb7, #182848)', 'linear-gradient(to right, #314755, #26a0da)', 'linear-gradient(to right, #360033, #0b8793)'], // blue 2
        ['linear-gradient(to right, #485563, #29323c)', 'linear-gradient(to right, #2b5876, #4e4376)', 'linear-gradient(to right, #614385, #516395)', 'linear-gradient(to right, #42275a, #734b6d)'], // muted blue-green-purple
        ['linear-gradient(to right, #4568dc, #b06ab3)', 'linear-gradient(to right, #3494e6, #ec6ead)', 'linear-gradient(to right, #6a3093, #a044ff)', 'linear-gradient(to right, #673ab7, #512da8)'], // purple
        ['linear-gradient(to right, #8e2de2, #4a00e0)', 'linear-gradient(to right, #f953c6, #b91d73)', 'linear-gradient(to right, #9d50bb, #6e48aa)', 'linear-gradient(to right, #da4453, #89216b)'], // purple 2
        ['linear-gradient(to right, #cc2b5e, #753a88)', 'linear-gradient(to right, #aa076b, #61045f)', 'linear-gradient(to right, #7b4397, #dc2430)', 'linear-gradient(to right, #c33764, #1d2671)'], // red purple
        ['linear-gradient(to right, #a73737, #7a2828)', 'linear-gradient(to right, #603813, #a79283)', 'linear-gradient(to right, #43372f, #9a8478)', 'linear-gradient(to right, #b79891, #94716b)'], // brown
        ['linear-gradient(to right, #616161, #93b0af)', 'linear-gradient(to right, #87929b, #2c3e50)', 'linear-gradient(to right, #232526, #414345)', 'linear-gradient(to right, #283048, #859398)'], // grey
      ],
      backgroundSwatchesMonochrome: [
        // Very Light - Light - Dark - Very Dark
        ['#fffde7', '#fff59d', '#fdb515', '#F9A825'], // yellow
        ['#fbe9e7', '#ffccbc', '#d84315', '#bf360c'], // orange
        ['#ffebee', '#ffcdd2', '#a12b3b', '#892432'], // red
        ['#fce4ec', '#f8bbd0', '#ad1457', '#880e4f'], // red
        ['#f3e5f5', '#e1bee7', '#6a1b9a', '#4a148c'], // purple
        ['#ede7f6', '#d1c4e9', '#4527a0', '#311b92'], // purple
        ['#e8eaf6', '#c5cae9', '#283593', '#1a237e'], // indigo
        ['#e3f2fd', '#bbdefb', '#1565c0', '#0d47a1'], // blue
        ['#e1f5fe', '#b3e5fc', '#0277bd', '#01579b'], // blue
        ['#e0f7fa', '#b2ebf2', '#00838f', '#006064'], // teal
        ['#e0f2f1', '#b2dfdb', '#00695c', '#004d40'], // green
        ['#e8f5e9', '#c8e6c9', '#2e7d32', '#1b5e20'], // green
        ['#fafafa', '#d7ccc8', '#4e342e', '#3e2723'], // brown
        ['#eceff1', '#cfd8dc', '#37474f', '#263238'], // grey blue
        ['#f5f5f5', defaultThemeBackgroundColor, '#424242', '#212121'], // black
      ],
      backgroundSwatchesMonochromeGradients: [
        // Light - Dark
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #fff59d)`, 'linear-gradient(to right, #fdb515, #F9A825)'], // yellow
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #ffccbc)`, 'linear-gradient(to right, #d84315, #bf360c)'], // orange
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #ffcdd2)`, 'linear-gradient(to right, #c62828, #b71c1c)'], // red
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #f8bbd0)`, 'linear-gradient(to right, #ad1457, #880e4f)'], // red
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #e1bee7)`, 'linear-gradient(to right, #6a1b9a, #4a148c)'], // purple
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #d1c4e9)`, 'linear-gradient(to right, #4527a0, #311b92)'], // purple
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #c5cae9)`, 'linear-gradient(to right, #283593, #1a237e)'], // indigo
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #bbdefb)`, 'linear-gradient(to right, #1565c0, #0d47a1)'], // blue
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #b3e5fc)`, 'linear-gradient(to right, #0277bd, #01579b)'], // blue
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #b2ebf2)`, 'linear-gradient(to right, #00838f, #006064)'], // teal
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #b2dfdb)`, 'linear-gradient(to right, #00695c, #004d40)'], // green
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #c8e6c9)`, 'linear-gradient(to right, #2e7d32, #1b5e20)'], // green
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #d7ccc8)`, 'linear-gradient(to right, #4e342e, #3e2723)'], // brown
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #cfd8dc)`, 'linear-gradient(to right, #37474f, #263238)'], // grey blue
        [`linear-gradient(to right, ${defaultThemeBackgroundColor}, #bfbfbf)`, 'linear-gradient(to right, #424242, #212121)'], // black
      ],
      primaryFontVariants: defaultFontVariants, // If you change the default from Poppins you'll need to update these.
      headerFontVariants: defaultFontVariants, // If you change the default from Poppins you'll need to update these.
      showPreview: false,
      themeData,
      currThemeForEdit: null,
      allFontImageNames,
      publicPath: process.env.BASE_URL || '',
      imageSizesForBackgroundImage: { small: 200, large: 800 },
      imageSizesForLogoImage: { small: 400 },
      eMetaTheme: eMetaTheme,
    };
  },
  created() {
    document.addEventListener('keydown', this.onCtrlS, false);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onCtrlS);
  },
  mounted() {
    this.themeData = getEmptyAestheticTheme();
    this.getCurrTheme();
  },
  computed: {
    allGoogleFonts(): Font[] {
      // Asar does not have a right bracket.
      return this.$store.state.fonts.filter((font: Font) => font.name.toLowerCase() !== 'asar');
    },
    themes(): AestheticTheme[] {
      return this.$store.getters.themes;
    },
  },
  watch: {
    themes() {
      this.getCurrTheme();
    },
  },
  methods: {
    onCtrlS(e: any) {
      // Ctrl + S save.
      if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault();
        (this as any).onSave();
      }
    },
    onBackgroundUpload(localImages: UploadedImage[]) {
      var img = new Image();
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        var imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
        var pixels = imageData?.data;
        var brightness = 0;

        if (!pixels) {
          return;
        }

        const chunk = Math.min(pixels.length, 10_000); // We just use a max of 10,000 pixels for brevity.
        let skippedPixels = 0;
        for (var i = 0; i < chunk; i++) {
          if (i % 4 !== 3) {
            // Ignore alpha.
            brightness += pixels[i];
          } else {
            skippedPixels++;
          }
        }

        brightness = brightness / (chunk - skippedPixels); // Average brightness
        if (brightness < 127) {
          this.themeData.metaTheme = eMetaTheme.dark;
        } else {
          this.themeData.metaTheme = eMetaTheme.light;
        }
        this.$forceUpdate();
      };
      const uploadedImage = localImages[0];
      if (uploadedImage.smallImageBase64Preview) {
        img.src = uploadedImage.smallImageBase64Preview;
      }
    },

    fontImageExists(name: string) {
      return allFontImageNames.includes(name.replace(/\s/g, ''));
    },
    getCurrTheme() {
      this.currThemeForEdit = this.themes.find((t) => t.id === getParameterByName('id')) || null;

      loadFonts(() => {
        const primary = this.getFont(this.themeData.primaryFontName);
        const header = this.getFont(this.themeData.primaryFontName);
        this.primaryFontVariants = formatVariants(primary.variants);
        this.headerFontVariants = formatVariants(header.variants);
        this.onPrimaryFontSelect(this.themeData.primaryFontName);
        this.onHeaderFontSelect(this.themeData.headerFontName);
      });

      if (!this.currThemeForEdit) {
        return;
      }

      this.onPrimaryFontSelect(this.currThemeForEdit.primaryFontName);
      this.onHeaderFontSelect(this.currThemeForEdit.headerFontName);

      const currThemeForEditCopy: any = { ...this.currThemeForEdit };

      for (const prop of Object.keys(currThemeForEditCopy)) {
        if (Array.isArray(currThemeForEditCopy[prop])) {
          (this.themeData as any)[prop] = [...currThemeForEditCopy[prop]];
        } else {
          if (typeof currThemeForEditCopy[prop] === 'object') {
            (this.themeData as any)[prop] = { ...currThemeForEditCopy[prop] };
          } else {
            (this.themeData as any)[prop] = currThemeForEditCopy[prop];
          }
        }
      }
    },
    setPresetTheme(themeId: string) {
      const theme = this.$store.getters.themes.find((theme: AestheticTheme) => theme.id === themeId) || null;
      if (!theme) {
        return;
      }
      const filledTheme = {...getEmptyAestheticTheme(), ...theme};
      this.themeData.backgroundColor = filledTheme.backgroundColor;
      this.themeData.accentColor = filledTheme.accentColor;
      this.themeData.primaryFontName = filledTheme.primaryFontName;
      this.themeData.primaryFontVariant = filledTheme.primaryFontVariant;
      this.themeData.primaryFontScale = filledTheme.primaryFontScale;
      this.themeData.headerFontName = filledTheme.headerFontName;
      this.themeData.headerFontVariant = filledTheme.headerFontVariant;
      this.themeData.headerFontName = filledTheme.headerFontName;
      this.themeData.uppercaseHeaderFont = filledTheme.uppercaseHeaderFont;
      this.themeData.ambientText = filledTheme.ambientText;
      this.themeData.cardStyle = filledTheme.cardStyle;
      this.onPrimaryFontSelect(filledTheme.primaryFontName);
      this.onHeaderFontSelect(filledTheme.headerFontName);
    },
    getFont(name: string): Font {
      return this.allGoogleFonts.filter((f: Font) => f.name === name)[0];
    },
    onPrimaryFontSelect(font: string) {
      if (!font || !this.getFont(font)) {
        return;
      }
      this.primaryFontVariants = formatVariants(this.getFont(font).variants);
      if (
        !Object.values(this.primaryFontVariants)
          .map((variant) => variant.value)
          .includes(this.themeData.primaryFontVariant)
      ) {
        this.themeData.primaryFontVariant = 'regular';
      }
    },
    onHeaderFontSelect(font: string) {
      if (!font || !this.getFont(font)) {
        return;
      }
      this.headerFontVariants = formatVariants(this.getFont(font).variants);
      if (
        !Object.values(this.headerFontVariants)
          .map((variant) => variant.value)
          .includes(this.themeData.headerFontVariant)
      ) {
        this.themeData.headerFontVariant = 'regular';
      }
    },
    onSave() {
      if (!(this.$refs.nameForm as any).validate()) {
        return;
      }

      if (!(this.$refs.bottomTextForm as any).validate()) {
        return;
      }

      const themeDataCopy = { ...this.themeData };

      // Reset premium theming options if premium is not activated.
      if (!isPremiumActivated()) {
        themeDataCopy.cardStyle = eCardStyle.round;
        themeDataCopy.images = [];
        themeDataCopy.secondaryImages = [];        
      }

      const promiseArray: Promise<any>[] = [];
      const storagePath = `userFiles/${this.$store.state.userId}/${themeDataCopy.id}`;

      handleImages(
        themeDataCopy,
        [
          {
            key: 'images',
            imageSizes: this.imageSizesForBackgroundImage,
          },
          {
            key: 'secondaryImages',
            imageSizes: this.imageSizesForLogoImage,
          },
        ],
        promiseArray,
        storagePath
      );

      Promise.all(promiseArray).then(() => {
        const gatewayFirestoreUpdate = {
          [`themes.${themeDataCopy.id}`]: themeDataCopy,
        };

        const gatewayLocalStoreUpdate = {
          themes: {
            ...this.$store.state.currUserModeGateway.themes,
            [themeDataCopy.id]: themeDataCopy,
          },
        };

        updateUserModeGatewayDoc(gatewayFirestoreUpdate, gatewayLocalStoreUpdate).then(() => {
          showSuccess(t.updateSuccessful);
          this.$router.push({ path: `/Themes` });
        });
      });
    },
  },
});
