
import { setUpNotifications } from '@/util-functions/notification-utils';
import { saveSettings } from '@/util-functions/user-utils';
import { Capacitor } from '@capacitor/core';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    webPushNotificationsAvailable() {
      return Boolean('Notification' in window);
    },
    nativePushNotificationsAvailable() {
      return Capacitor.isNativePlatform();
    },    
    getShouldNotificationSoundRepeat: {
      get(): boolean {
        return this.$store.state.currUser.settings.shouldNotificationSoundRepeat;
      },
      set(val: boolean) {
        saveSettings({ shouldNotificationSoundRepeat: val });
      },
    },
    getEmailNotificationsForPayments: {
      get(): boolean {
        return this.$store.state.currUser.settings.getEmailNotificationsForPayments;
      },
      set(val: boolean) {
        saveSettings({ getEmailNotificationsForPayments: val });
      },
    },
    getEmailNotificationsForBookings: {
      get(): boolean {
        return this.$store.state.currUser.settings.getEmailNotificationsForBookings;
      },
      set(val: boolean) {
        saveSettings({ getEmailNotificationsForBookings: val });
      },
    },
    getEmailNotificationsForCustomForms: {
      get(): boolean {
        return this.$store.state.currUser.settings.getEmailNotificationsForCustomForms;
      },
      set(val: boolean) {
        saveSettings({ getEmailNotificationsForCustomForms: val });
      },
    },
    getEmailNotificationsForMarketing: {
      get(): boolean {
        return this.$store.state.currUser.settings.getEmailNotificationsForMarketing;
      },
      set(val: boolean) {
        saveSettings({ getEmailNotificationsForMarketing: val });
      },
    },
    getWebPushNotificationsForPayments: {
      get(): boolean {
        return this.$store.state.currUser.settings.getWebPushNotificationsForPayments;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getWebPushNotificationsForPayments: val });
      },
    },
    getWebPushNotificationsForBookings: {
      get(): boolean {
        return this.$store.state.currUser.settings.getWebPushNotificationsForBookings;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getWebPushNotificationsForBookings: val });
      },
    },
    getWebPushNotificationsForCustomForms: {
      get(): boolean {
        return this.$store.state.currUser.settings.getWebPushNotificationsForCustomForms;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getWebPushNotificationsForCustomForms: val });
      },
    },
    getNativePushNotificationsForPayments: {
      get(): boolean {
        return this.$store.state.currUser.settings.getNativePushNotificationsForPayments;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getNativePushNotificationsForPayments: val });
      },
    },
    getNativePushNotificationsForBookings: {
      get(): boolean {
        return this.$store.state.currUser.settings.getNativePushNotificationsForBookings;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getNativePushNotificationsForBookings: val });
      },
    },
    getNativePushNotificationsForCustomForms: {
      get(): boolean {
        return this.$store.state.currUser.settings.getNativePushNotificationsForCustomForms;
      },
      set(val: boolean) {
        setUpNotifications();
        saveSettings({ getNativePushNotificationsForCustomForms: val });
      },
    },
  },
});
