
import { isAtMaxModes } from '@/util-functions/misc-utils';
import { updateUserDoc } from '@/util-functions/user-utils';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    shortcutMode1: {
      get(): string | null {
        return this.$store.state.currUser.modeShortCut1 || null;
      },
      set(value: boolean) {
        updateUserDoc({
          modeShortCut1: value,
        } as any);
      },
    },
    shortcutMode2: {
      get(): string | null {
        return this.$store.state.currUser.modeShortCut2 || null;
      },
      set(value: boolean) {
        updateUserDoc({
          modeShortCut2: value,
        } as any);
      },
    },
    modesArray(): AnyMode[] {
      return this.$store.getters.sortedModes;
    },
  },
  methods: {
    createNewMode() {
      if (!isAtMaxModes()) {
        this.$router.push({ path: '/SitchForm' });
      }
    },
  },
});
