
import { getEmptyExternalUseMode } from '@/constants';
import { currFirestore } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError, generateId } from '@/util-functions/misc-firestore-utils';
import { isAtMaxModes, isiOS } from '@/util-functions/misc-utils';
import { showSuccess, showConfirmation } from '@/util-functions/notice-utils';
import { getUserDoc, updateUserLocal } from '@/util-functions/user-utils';
import validationRules from '@/validation-rules';
import { deleteField, doc, getDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import Vue from 'vue';

export default Vue.extend({
  data(): {
    selectedModes: ExternalUseMode[];
    isiOS: boolean;
  } {
    const ua: string = navigator.userAgent.toLowerCase();
    return {
      selectedModes: [],
      isiOS: isiOS() || (ua.includes('safari') && !ua.includes('chrome')),
    };
  },
  computed: {
    modeGroups(): ExternalUseModeGroupOnUser[] {
      const externalUseModeGroups = this.$store.state.currUser.externalUseModeGroups;
      return (Object.values(externalUseModeGroups || {}) as ExternalUseModeGroupOnUser[]).sort((a: ExternalUseModeGroupOnUser, b: ExternalUseModeGroupOnUser) => {
        return a.dateCreated - b.dateCreated;
      });
    },
    modesForSelection(): ExternalUseMode[] {
      const usedNameCount: { [name: string]: number } = {};
      return (this.$store.getters.teamAndPersonalModesArray as (Mode | ProccessedTeamMode)[]).map((mode: Mode | ProccessedTeamMode) => {
        const externalUseMode: ExternalUseMode = {
          ...getEmptyExternalUseMode(),
          docId: mode.docId,
          linkId: mode.linkId,
          type: mode.type,
          name: mode.name,
          ownerId: (mode as any).ownerId || '',
          displayName: mode.displayName || '',
        };
        usedNameCount[mode.name] = usedNameCount[mode.name] ? usedNameCount[mode.name] + 1 : 1;
        if (usedNameCount[mode.name] > 1) {
          (externalUseMode.originalName = mode.name), (externalUseMode.name = `${mode.name} (${mode.displayName || usedNameCount[mode.name]})`);
        }
        return externalUseMode;
      });
    },
  },
  methods: {
    moveModeArrayItem(array: ExternalUseMode[], externalUseMode: ExternalUseMode, positionDelta: 1 | -1) {
      let oldIndex = 0;
      array.forEach((el, index) => {
        if (el.docId === externalUseMode.docId) {
          oldIndex = index; // Find the index of the mode being moved.
        }
      });
      let newIndex = oldIndex + positionDelta; // Find where it's being moved to.
      let temp;
      if (newIndex === -1) {
        newIndex = array.length - 1;
      } else if (newIndex === array.length) {
        newIndex = 0;
      }
      // Swap.
      temp = array[newIndex];
      array[newIndex] = externalUseMode;
      array[oldIndex] = temp;
    },
    moveModeArrayItemAndUpdate(group: ExternalUseModeGroup, externalUseMode: ExternalUseMode, positionDelta: 1 | -1) {
      const externalUseModeArray = group.modes;
      this.moveModeArrayItem(externalUseModeArray, externalUseMode, positionDelta);
      this.updateModesArrayForGroup(externalUseModeArray, group.docId);
    },
    createNewMode() {
      if (!isAtMaxModes()) {
        this.$router.push({ path: '/SitchForm' });
      }
    },
    segmentCode(entryCode: string) {
      const firstThree = `<span class="code-segment">${entryCode.substring(0, 3)}</span>`;
      const middleThree = `<span class="code-segment">${entryCode.substring(3, 6)}</span>`;
      const lastThree = `<span class="code-segment">${entryCode.substring(6, 9)}</span>`;
      return firstThree + middleThree + lastThree;
    },
    updateNameFor(name: string, groupDocId: string) {
      if (!name) {
        return;
      }
      const isValid = validationRules.requiredNameRules.every((rule) => {
        const result: boolean | string = rule(name);
        if (result === true) {
          return true;
        } else {
          showError(result as string);
          return false;
        }
      });

      if (isValid) {
        updateDoc(getUserDoc(), {
          [`externalUseModeGroups.${groupDocId}.name`]: name,
        })
          .then(() => {
            showSuccess(t.updateSuccessful);
          })
          .catch((error: any) => {
            showError(`Could not update the user.`, error, true);
          });
      }
    },
    onRemoveMode(modeDocId: string) {
      this.selectedModes = this.selectedModes.filter((mode) => mode.docId !== modeDocId);
    },
    updateModesForGroup(newModeArray: ExternalUseMode[], groupDocId: string) {
      this.updateModesArrayForGroup(newModeArray, groupDocId);
    },
    onRemoveModeFromGroup(modeDocId: string, groupDocId: string) {
      const externalUseModeGroupOnUser = this.$store.state.currUser.externalUseModeGroups[groupDocId] as ExternalUseModeGroupOnUser;
      const newExternalUseModeArray: ExternalUseMode[] = externalUseModeGroupOnUser.modes.filter((mode) => mode.docId !== modeDocId);
      this.updateModesArrayForGroup(newExternalUseModeArray, groupDocId);
    },
    updateModesArrayForGroup(newModeArray: ExternalUseMode[], groupDocId: string) {
      const batch = writeBatch(currFirestore);

      // Change names back to basic forms without the bracketed descriptors.
      const formattedNewModeArray: ExternalUseMode[] = newModeArray.map((mode: ExternalUseMode) => {
        const modeCopy = { ...mode };
        if (modeCopy.originalName) {
          modeCopy.name = modeCopy.originalName;
          delete modeCopy.originalName;
        }
        return modeCopy;
      });

      batch.update(getUserDoc(), {
        [`externalUseModeGroups.${groupDocId}.modes`]: formattedNewModeArray,
      });

      const groupDoc = doc(currFirestore, 'externalUseModeGroups', groupDocId);

      batch.update(groupDoc, {
        modes: formattedNewModeArray,
      });

      batch.commit().then(() => {
        const externalUseModeGroupOnUser = this.$store.state.currUser.externalUseModeGroups[groupDocId] as ExternalUseModeGroupOnUser;
        updateUserLocal({
          externalUseModeGroups: {
            ...this.$store.state.currUser.externalUseModeGroups,
            [groupDocId]: {
              ...externalUseModeGroupOnUser,
              modes: formattedNewModeArray,
            },
          },
          dateUpdated: Timestamp.fromMillis(Date.now()),
        });
      });
    },
    createNewGroup() {
      if (!this.selectedModes.length) {
        showError(t.selectAtLeastOneMode);
        return;
      }

      const entryCode = generateId().slice(0, 9).toUpperCase();
      const exernalUseModeGroupDoc = doc(currFirestore, 'externalUseModeGroups', entryCode);

      getDoc(exernalUseModeGroupDoc).then((docSnap) => {
        if (!docSnap.exists()) {
          const batch = writeBatch(currFirestore);

          // Change names back to basic forms without the bracketed descriptors.
          const selectedModes: ExternalUseMode[] = this.selectedModes.map((mode: ExternalUseMode) => {
            const modeCopy = { ...mode };
            if (modeCopy.originalName) {
              modeCopy.name = modeCopy.originalName;
              delete modeCopy.originalName;
            }
            return modeCopy;
          });

          // Update user to keep track of the new group in the manager.
          const externalUseModeGroupOnUser: ExternalUseModeGroupOnUser = {
            docId: entryCode,
            name: '',
            modes: selectedModes,
            dateCreated: Date.now(),
          };

          // Create a doc for public exposure
          const externalUseModeGroup: ExternalUseModeGroup = {
            docId: entryCode,
            userId: this.$store.state.userId,
            modes: selectedModes,
            dateCreated: Timestamp.fromMillis(Date.now()),
          };

          batch.set(exernalUseModeGroupDoc, externalUseModeGroup);
          batch.update(getUserDoc(), {
            [`externalUseModeGroups.${entryCode}`]: externalUseModeGroupOnUser,
          });

          batch
            .commit()
            .then(() => {
              showSuccess(t.updateSuccessful);
              const newExternalUseModeGroups = {
                ...this.$store.state.currUser.externalUseModeGroups,
                [entryCode]: externalUseModeGroupOnUser,
              };
              updateUserLocal({
                externalUseModeGroups: newExternalUseModeGroups,
                dateUpdated: Timestamp.fromMillis(Date.now()),
              });
            })
            .catch((error) => {
              showError(`Could not add new group.`, error, true);
            });
        } else {
          showError(`Something went wrong. Try again.`);
        }
      });
    },
    deleteGroup(group: ExternalUseModeGroup) {
      const entryCode = group.docId;
      showConfirmation(t.areYouSureYouWantToDeleteThisGroup, () => {
        const batch = writeBatch(currFirestore);
        const exernalUseModeGroupDoc = doc(currFirestore, 'externalUseModeGroups', entryCode);

        // Update user to keep track of the new group in the manager.
        batch.update(getUserDoc(), {
          [`externalUseModeGroups.${entryCode}`]: deleteField(),
        });

        // Create a doc for public exposure
        batch.delete(exernalUseModeGroupDoc);

        batch
          .commit()
          .then(() => {
            showSuccess(t.updateSuccessful);
            const newExternalUseModeGroups = {
              ...this.$store.state.currUser.externalUseModeGroups,
            };
            delete newExternalUseModeGroups[entryCode];
            updateUserLocal({
              externalUseModeGroups: newExternalUseModeGroups,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
          })
          .catch((error) => {
            showError(`Could not delete the group.`, error, true);
          });
      });
    },
  },
});
