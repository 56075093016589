
import Vue from 'vue';
import validationRules from '@/validation-rules';
import { endpoints } from '@/constants';
import { deleteField } from 'firebase/firestore';
import { standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getParameterByName, addHttp } from '@/util-functions/misc-utils';
import { showConfirmation, showSuccess } from '@/util-functions/notice-utils';
import { getStripeAccountData, onConnectStripeAccount } from '@/util-functions/stripe-utils';
import { updateUserModeGatewayLocal, updateUserModeGatewayDoc } from '@/util-functions/user-utils';

export default Vue.extend({
  data(): {
    headers: TableHeaders;
    rules: typeof validationRules;
    showSelectedSripeAccountDialog: boolean;
    selectedSripeAccount: StripeAccount | null;
    showStripeInfo: boolean;
    stripeAccountForm: Partial<StripeAccount>;
  } {
    return {
      headers: [
        { text: t?.name, value: 'displayName', sortable: false },
        { text: t?.email, value: 'email', sortable: false },
        { text: t?.currency, value: 'defaultCurrency', sortable: false },
        { text: t?.actions, sortable: false, align: 'center' },
      ],
      rules: validationRules,
      showSelectedSripeAccountDialog: false,
      selectedSripeAccount: null,
      showStripeInfo: false,
      stripeAccountForm: {
        email: '',
        url: '',
        phone: '',
        customerFacingBusinessName: '',
      },
    };
  },
  computed: {        
    stripeAccounts(): StripeAccount[] {
      return this.$store.getters.stripeAccounts;
    },
  },
  mounted() {
    const code: string = getParameterByName('code');
    const stripeError: string = getParameterByName('error');
    const errorDescription: string = getParameterByName('error_description');

    if (code) {
      Vue.nextTick(() => {
        showLoading();
        history.replaceState({}, '', location.href.split('?')[0]); // Remove the query string, since refreshing may cause errors if it is present.
        standardApiFetch(endpoints.connectStripeAccount, {
          code,
          userId: this.$store.state.userId,
          defaultEmail: this.$store.state.currUser.email,
        })
          .then((response) => {
            const stripeAccount: StripeAccount = response.successfulResponse.stripeAccount;
            updateUserModeGatewayLocal({
              stripeAccountsMap: {
                ...this.$store.state.currUserModeGateway.stripeAccountsMap,
                [stripeAccount.stripeUserId]: stripeAccount,
              },
            });
          })
          .finally(() => {
            hideLoading();
          });
      });
    }
    if (errorDescription) {
      showError(errorDescription);
    } else if (stripeError) {
      showError(`Stripe account connect process failed for an unknown reason.`, null, true);
    }
  },
  methods: {
    purgeDisconnectedAccounts() {
      // Check if current stripe accounts are still connected.
      const promiseArray: Promise<any>[] = [];
      const stripeAccountsToPurge: string[] = [];
      this.stripeAccounts.forEach((stripeAccount) => {
        const promise = getStripeAccountData(stripeAccount.stripeUserId);
        promise.catch((response) => {
          // Account is no longer connected in this case so remove it.
          if (!response || ['StripePermissionError', 'StripeInvalidRequestError'].includes(response.error.type)) {
            stripeAccountsToPurge.push(stripeAccount.stripeUserId);
          }
        });
        promiseArray.push(promise);
      });

      Promise.allSettled(promiseArray).then(() => {
        if (stripeAccountsToPurge) {
          const gatewayFirestoreUpdate: any = {};
          const newStripeAccountsMap = { ...this.$store.state.currUserModeGateway.stripeAccountsMap };
          stripeAccountsToPurge.forEach((stripeUserId) => {
            gatewayFirestoreUpdate[`stripeAccountsMap.${stripeUserId}`] = deleteField();
            delete newStripeAccountsMap[stripeUserId];
          });
          const gatewayLocalStoreUpdate = {
            stripeAccountsMap: newStripeAccountsMap,
          };
          updateUserModeGatewayDoc(gatewayFirestoreUpdate, gatewayLocalStoreUpdate);
        }
      });
    },
    showSelectedSripeAccountDialogFunc(selectedSripeAccount: any) {
      this.showSelectedSripeAccountDialog = true;
      this.selectedSripeAccount = selectedSripeAccount;
      this.stripeAccountForm.email = selectedSripeAccount.email;
      this.stripeAccountForm.url = selectedSripeAccount.url;
      this.stripeAccountForm.phone = selectedSripeAccount.phone;
      this.stripeAccountForm.customerFacingBusinessName = selectedSripeAccount.customerFacingBusinessName;
    },
    hideSelectedSripeAccountDialogFunc() {
      this.showSelectedSripeAccountDialog = false;
      this.selectedSripeAccount = null;
    },
    onConnectStripeAccount() {
      onConnectStripeAccount();
    },
    removeStripeAccount() {
      showConfirmation(t?.areYouSureYouWantToDeleteThisStripeAccount, () => {
        const stripeUserId = this.selectedSripeAccount?.stripeUserId || '';
        // Make Firestore update.
        const gatewayFirestoreUpdate: any = {
          [`stripeAccountsMap.${stripeUserId}`]: deleteField(),
        };
        // Make local update.
        const newStripeAccountsMap = { ...this.$store.state.currUserModeGateway.stripeAccountsMap };
        delete newStripeAccountsMap[stripeUserId];
        const gatewayLocalStoreUpdate = {
          stripeAccountsMap: newStripeAccountsMap,
        };
        updateUserModeGatewayDoc(gatewayFirestoreUpdate, gatewayLocalStoreUpdate).then(() => {
          this.hideSelectedSripeAccountDialogFunc();
          showSuccess(t.stripeAccountRemoved);
        });
      });
    },
    saveStripeAccountChanges() {
      if (!(this.$refs.stripeAccountForm as any).validate()) {
        return;
      }

      if (!this.selectedSripeAccount) {
        showError(t.noStripeAccountSelected);
        return;
      }

      const emailField = `stripeAccountsMap.${this.selectedSripeAccount.stripeUserId}.email`;
      const urlField = `stripeAccountsMap.${this.selectedSripeAccount.stripeUserId}.url`;
      const phoneField = `stripeAccountsMap.${this.selectedSripeAccount.stripeUserId}.phone`;
      const customerFacingBusinessNameField = `stripeAccountsMap.${this.selectedSripeAccount.stripeUserId}.customerFacingBusinessName`;

      updateUserModeGatewayDoc(
        {
          [emailField]: this.stripeAccountForm.email || '',
          [phoneField]: this.stripeAccountForm.phone || '',
          [urlField]: addHttp(this.stripeAccountForm.url) || '',
          [customerFacingBusinessNameField]: this.stripeAccountForm.customerFacingBusinessName || '',
        },
        {
          stripeAccountsMap: {
            ...this.$store.state.currUserModeGateway.stripeAccountsMap,
            [this.selectedSripeAccount.stripeUserId]: {
              ...this.$store.state.currUserModeGateway.stripeAccountsMap[this.selectedSripeAccount.stripeUserId],
              email: this.stripeAccountForm.email,
              phone: this.stripeAccountForm.phone,
              url: addHttp(this.stripeAccountForm.url),
              customerFacingBusinessName: this.stripeAccountForm.customerFacingBusinessName,
            },
          },
        }
      ).then(() => {
        showSuccess(t.updateSuccessful);
        this.hideSelectedSripeAccountDialogFunc();
      });
    },
  },
});
